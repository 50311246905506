
import {
    defineComponent, reactive, ref, defineAsyncComponent
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import List from '@/components/common/list';
// import { alarmRecordsApi, pmCommonApi } from '@/api';
import ExportLogDialog from '@/views/pm/alarm-records/components/export-log-dialog.vue';
// import { community } from '@/data';
// import moment from 'moment';

const column = [
    {
        name: 'AlarmTime',
        label: WordList.AlarmTime,
        type: 'customize'
    }, {
        name: 'Building',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Device',
        label: WordList.TabelUpdateBoxDevice
    }, {
        name: 'Type',
        label: WordList.TabelKeyInHtmlType
    }];
export default defineComponent({
    components: {
        ExportLogDialog,
        propertyBreadCrumb,
        List
    },
    setup() {
        const tabsData = {
            emergencyAlarm: {
                label: WordList.EmergencyAlarm,
                name: 'emergencyAlarm',
                component: defineAsyncComponent(() => import('@/views/pm/alarm-records/components/recoeds-emergency-alarm.vue'))
            },
            alarmingAlarm: {
                label: WordList.ArmingAlarm,
                name: 'alarmingAlarm',
                component: defineAsyncComponent(() => import('@/views/pm/alarm-records/components/recoeds-alarming-alarm.vue'))
            }
        };
        const activeTab = ref('emergencyAlarm');
        // 头部切换标签，同时请求user数据
        function changeTab(tab: {paneName: string}) {
            activeTab.value = tab.paneName;
        }

        return {
            tabsData,
            activeTab,
            changeTab
        };
    }
});
