import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { style: {"display":"inline-block","padding":"0 20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_property_bread_crumb = _resolveComponent("property-bread-crumb")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_property_bread_crumb, {
      headers: [{ label: _ctx.$wordList.AlarmRecords }]
    }, null, 8, ["headers"]),
    _createVNode(_component_el_tabs, {
      style: {"background-color":"#ffffff"},
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = $event)),
      onTabClick: _ctx.changeTab
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(Object.keys(_ctx.tabsData), (key, index) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            ref: "tabPaneRef",
            key: `${_ctx.tabsData[key].label}`,
            name: _ctx.tabsData[key].name
          }, {
            label: _withCtx(() => [
              _createVNode("span", _hoisted_1, _toDisplayString(`${_ctx.tabsData[key].label}`), 1)
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 128)),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabsData[_ctx.activeTab].component)))
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])
  ]))
}