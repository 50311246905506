
import dialogShell from '@/components/common/dialog-shell/index.vue';
import HttpRequest from '@/util/axios.config';
import {
    defineComponent, ref, Ref
} from 'vue';
import { format } from '@/methods/date';
import moment from 'moment';
import { downloadFileHandler } from '@/util/system';

export default defineComponent({
    components: {
        dialogShell
    },
    setup(props, { emit }) {
        const formRef: Ref<any> = ref({});
        const beginTime = moment().subtract(1, 'M').format('YYYY-MM-DD');
        const endTime = moment().format('YYYY-MM-DD');
        const formData = ref({
            BeginTime: beginTime,
            EndTime: endTime
        });
        const beginOptions = (time: Date) => (
            time.getTime() < new Date(moment(beginTime).subtract(1, 'day').format('YYYY-MM-DD')).getTime()
            || time.getTime() > new Date().getTime()
        );

        const endOptions = (time: Date) => (
            time.getTime() < new Date(beginTime).getTime()
            || time.getTime() > new Date().getTime()
        );

        const downloadRef: Ref<any> = ref({});
        const downloadUrl = ref('');
        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;

                const params = { ...formData.value };
                params.BeginTime = format(params.BeginTime, 'YYYY-MM-DD');
                params.EndTime = format(params.EndTime, 'YYYY-MM-DD');

                // const downloadElement = downloadRef.value;
                // downloadElement.setAttribute('target', '_blank');
                // downloadElement.setAttribute('download', '');
                // downloadElement.setAttribute('href', `${HttpRequest.host}web-server/v3/web/community/alarm/exportLog?Token=${
                //     localStorage.getItem('token')}&ProjectID=${localStorage.getItem('communityID')}&BeginTime=${params.BeginTime}&EndTime=${
                //     format(params.EndTime)}&t=${Math.round(new Date().getTime() / 1000).toString()}`);
                // downloadElement.click();

                downloadFileHandler(`${HttpRequest.host}web-server/v3/web/community/alarm/exportLog?BeginTime=${params.BeginTime}&EndTime=${
                    format(params.EndTime)}&t=${Math.round(new Date().getTime() / 1000).toString()}`);
                return true;
            });
        };
        return {
            formRef,
            submit,
            formData,
            emit,
            downloadUrl,
            downloadRef,
            beginOptions,
            endOptions
        };
    }
});

